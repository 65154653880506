
import {computed, defineComponent, ref} from 'vue';
import Close from '@/components/icons/Close.vue';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import {AdminSettlementHoldParamDTOSettlementStatusEnum} from '@/openapi';

export default defineComponent({
  name: 'SettlementDetailModal',
  props: {
    isOpen: {
      type: Boolean,
    },
    settlementData: {
      type: Object,
      required: true
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const input = computed(() => {
      return {
        reason: props.settlementData?.holdReason,
      };
    });


    const handleSubmit = async (orderId) => {
      if(props.settlementData.settlementStatus === 'hold') {
        const body = {
          'holdReason': input.value.reason,
          orderId,
          'settlementStatus': AdminSettlementHoldParamDTOSettlementStatusEnum.Waiting
        }
        if(confirm('보류를 해제하고 정산대기중으로 바꾸시겠습니까?')) {
          try {
            await partnerAPI.adminOrder.adminOrderHold({
              param: body
            });
          } catch (e) {
            alert(getServerErrorMessage(e));
          }
        }
      }else {
        if(!input.value.reason) {
          alert('보류사유를 입력해주세요.')
          return
        }
        const body = {
          'holdReason': input.value.reason,
          orderId,
          'settlementStatus': AdminSettlementHoldParamDTOSettlementStatusEnum.Hold
        }
        try {
          await partnerAPI.adminOrder.adminOrderHold({
            param: body
          });
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }

      emit('fetchDetailModal');
      onClose();
    };

    const onClose = () => {
      emit('onClose');
    };

    return {
      input,
      convertNumWithComma,
      handleSubmit,
      onClose,
    };
  },
});
