<template>
  <div
    v-if="isOpen"
    class="
      fixed
      z-50
      inset-0
      bg-black bg-opacity-40
      flex
      items-center
      justify-center
    "
  >
    <div class="bg-white relative">
      <article class="settlement-modal">
        <header class="modal-header">
          <h1 class="modal__title">
            정산 상세내역
          </h1>
          <button class="modal__close-button" @click="onClose">
            <Close />
          </button>
        </header>
        <div class="modal-content">
          <div class="price-area">
            <p class="price-content">
              <span class="price-content__data price-content__title">판매가</span>
              <span class="price-content__data">
                <strong class="price-content__price">
                  {{ convertNumWithComma(settlementData.totalPrice, '') }}
                </strong>
                <span class="price-content__unit">KRW</span>
              </span>
            </p>
            <p class="price-content">
              <span class="price-content__data price-content__title">부가세(VAT)</span>
              <span class="price-content__data">
                <strong class="price-content__price">
                  {{ convertNumWithComma(settlementData.vat, '') }}
                </strong>
                <span class="price-content__unit">KRW</span>
              </span>
            </p>
            <p class="price-content">
              <span class="price-content__data price-content__title">배송료</span>
              <span class="price-content__data">
                <strong class="price-content__price">
                  {{ convertNumWithComma(settlementData.totalShippingFee, '') }}
                </strong>
                <span class="price-content__unit">KRW</span>
              </span>
            </p>
            <p class="price-content has-border">
              <span class="price-content__data price-content__title">결제금액</span>
              <span class="price-content__data">
                <strong class="price-content__price">
                  {{ convertNumWithComma(settlementData.payAmount, '') }}
                </strong>
                <span class="price-content__unit">KRW</span>
              </span>
            </p>
            <p class="price-content">
              <span class="price-content__data price-content__title">판매수수료</span>
              <span class="price-content__data">
                <strong class="price-content__price">
                  {{ convertNumWithComma(settlementData.totalFee - (settlementData.pgFee || 0), '') }}
                </strong>
                <span class="price-content__unit">KRW</span>
              </span>
            </p>
            <p
              v-if="settlementData.pgFee"
              class="price-content has-border"
            >
              <span class="price-content__data price-content__title">결제(PG)수수료</span>
              <span class="price-content__data">
                <strong class="price-content__price">
                  {{ convertNumWithComma(settlementData.pgFee || 0, '') }}
                </strong>
                <span class="price-content__unit">KRW</span>
              </span>
            </p>
            <p class="price-content">
              <span class="price-content__data price-content__title">정산금액</span>
              <span class="price-content__data">
            <strong class="price-content__price">
              {{ convertNumWithComma(settlementData.settlementAmount, '') }}
            </strong>
            <span class="price-content__unit">KRW</span>
          </span>
            </p>
          </div>
          <div class="mt-10 input-part-area flex items-center">
            <input id="reason" v-model="input.reason" type="text" class="admin-input px-2 border border-black h-10 w-full" placeholder="보류사유를 입력해주세요.">
          </div>
        </div>
        <div class="justify-center confirm-modal-button-area">
          <button class="confirm-modal__button full confirm-modal__button-cancel"
                  @click="onClose">
            닫기
          </button>
          <button class="confirm-modal__button bg-black full"
            @click="handleSubmit(settlementData.orderId)"
          >
            <template v-if="settlementData.settlementStatus === 'hold'">
              보류해제
            </template>
            <template v-else>
              보류등록
            </template>
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import Close from '@/components/icons/Close.vue';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import {AdminSettlementHoldParamDTOSettlementStatusEnum} from '@/openapi';

export default defineComponent({
  name: 'SettlementDetailModal',
  props: {
    isOpen: {
      type: Boolean,
    },
    settlementData: {
      type: Object,
      required: true
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const input = computed(() => {
      return {
        reason: props.settlementData?.holdReason,
      };
    });


    const handleSubmit = async (orderId) => {
      if(props.settlementData.settlementStatus === 'hold') {
        const body = {
          'holdReason': input.value.reason,
          orderId,
          'settlementStatus': AdminSettlementHoldParamDTOSettlementStatusEnum.Waiting
        }
        if(confirm('보류를 해제하고 정산대기중으로 바꾸시겠습니까?')) {
          try {
            await partnerAPI.adminOrder.adminOrderHold({
              param: body
            });
          } catch (e) {
            alert(getServerErrorMessage(e));
          }
        }
      }else {
        if(!input.value.reason) {
          alert('보류사유를 입력해주세요.')
          return
        }
        const body = {
          'holdReason': input.value.reason,
          orderId,
          'settlementStatus': AdminSettlementHoldParamDTOSettlementStatusEnum.Hold
        }
        try {
          await partnerAPI.adminOrder.adminOrderHold({
            param: body
          });
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }

      emit('fetchDetailModal');
      onClose();
    };

    const onClose = () => {
      emit('onClose');
    };

    return {
      input,
      convertNumWithComma,
      handleSubmit,
      onClose,
    };
  },
});
</script>

<style scoped lang="scss">
.settlement-modal {
  width: 460px;
  height: 600px;
  .modal {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 21px 0 30px;
      height: 83px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__title {
      font-size: 32px;
      font-weight: 800;
      line-height: 1.21;
    }

    &-content {
      padding: 0 24px;
    }
  }
}
.confirm-modal-button-area {
  position: absolute;
  bottom: 32px;
  left: 24px;
  width: calc(100% - 48px);
  display: flex;
  align-items: center;

  .confirm-modal__button {
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    width: 60%;
    color: #fff;

    &-cancel {
      color: #202020;
      width: 37%;
      background: #fff;
      border: 1px solid #202020;
      box-sizing: border-box;
      margin-right: 3%;
    }

    &.full {
      width: 100%;
    }
  }
}

.price-area {
  padding-top: 30px;
  font-size: 16px;
  color: #000000;
  .price-content {
    display: flex;
    align-items: center;
    &.has-border {
      border-bottom: 1px solid;
      padding-bottom: 20px;
    }
    & + .price-content {
      margin-top: 18px;
    }
    &__title {
      display: inline-block;
      min-width: 110px;
      text-align: left;
    }
    &__price {
      display: inline-block;
      min-width: 200px;
      font-weight: normal;
      text-align: right;
    }
    &__unit {
      min-width: 47px;
      display: inline-block;
      text-align: right;
    }
  }
}
</style>
