<template>
  <div
    v-if="partnerData"
    class="partner-filter mb-4 text-sm inline-flex items-center"
  >
    판매자 :
    <div
      class="w-8 h-8 bg-gray-100 bg-cover rounded-full inline-flex ml-3"
      :style="{
        backgroundImage: `url(${partnerData.partner.profileImage})`,
      }"
    ></div>
    <b class="partner-filter__name mx-3">{{ partnerData.partner.companyName }}</b>
    <button
      class="partner-filter__close"
      @click="$emit('close')"
    >
      <close-filter />
    </button>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import CloseFilter from './icons/CloseFilter.vue'
import partnerAPI from '@/service/partnerAPI'
export default {
  name: 'PartnerFilter',
  components: {
    CloseFilter
  },
  props: {
    partnerId: {
      type: Number,
      require: true
    }
  },
  setup(props) {
    const partnerData = ref(null)

    const getPartner = async () => {
      if (props.partnerId) {
        const { data } = await partnerAPI.adminMember.adminMemberPartner({
          partnerId: props.partnerId
        })
        partnerData.value = data.data
      }
    }

    onMounted(getPartner)

    return {
      partnerData
    }
  }
}
</script>

<style scoped lang="scss">
.partner-filter {
  background: #CFF7FB;
  border-radius: 50px;
  padding: 16px 20px;
  &__name {
    font-size: 18px;
    color: #000;
  }
}
</style>
