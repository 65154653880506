
import datePattern from '@/constants/datePattern';
import { defineComponent, onMounted, watch, reactive, ref, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { format } from 'date-fns/esm';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import ExcelDownIcon from '@/components/icons/ExcelDownIcon.vue';
import router from '@/router';
import partnerAPI from '@/service/partnerAPI';
import convertNumWithComma from '@/utils/convertNumWithComma';
import getServerErrorMessage from "@/utils/getServerErrorMessage";
import salesAndOrderStatus from "@/constants/salesAndOrderStatus";
import XLSX from "xlsx";
import _ from 'lodash'

export default defineComponent({
  name: 'SettlementIndex',
  components: {
    ListFilter,
    ListTable,
    Container,
    Pagination,
    ExcelDownIcon,
  },
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<any>({
      partnerId: Number(query.partnerId) || undefined,
      start: (query.start as string) || '',
      end: (query.end as string) || '',
      categoryDepth1Id: Number(query.categoryDepth1Id) || undefined,
      orderType: query.orderType || '',
      enableBid: query.enableBid === 'true' ? true : query.enableBid === 'false' ? false : undefined,
      settlementStatus: query.settlementStatus || '',
      showSeoulAuction: query.showSeoulAuction === 'true' ? true : undefined,
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
      sortBy: (query.sortBy as string) || undefined,
    });

    const isFilterOn = ref(query.isFilter === 'true');

    // 정산 목록
    const list = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const summaryData = ref({});

    const toggleApplyFilters = () => {
      isFilterOn.value = !isFilterOn.value;
      executeSearch();
    };

    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };

    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const handleClickSearch = () => {
      console.log('베르나');
      filterParams.page = 0;
      executeSearch();
    };

    const searchPartner = ({ partnerId }) => {
      console.log('서치파트너', partnerId)
      filterParams.partnerId = partnerId
      filterParams.page = 0
      executeSearch()
    }

    const removeSearchPartner = () => {
      filterParams.partnerId = undefined
      filterParams.page = 0
      executeSearch()
    }

    const handleChangeSort = (sortBy) => {
      filterParams.sortBy = sortBy
      executeSearch()
    }

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchAdjustmentLists();
      router.push({
        path: window.location.pathname,
        query: {
          partnerId: filterParams.partnerId || '',
          start: filterParams.start || '',
          end: filterParams.end || '',
          categoryDepth1Id: filterParams.categoryDepth1Id || '',
          orderType: filterParams.orderType || '',
          enableBid: String(filterParams.enableBid) || '',
          settlementStatus: filterParams.settlementStatus || '',
          showSeoulAuction: filterParams.showSeoulAuction || undefined,
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
          isFilter: String(isFilterOn.value),
          sortBy: String(filterParams.sortBy) || ''
        },
      });
    };

    const getAppliedFilterParams = () =>
      isFilterOn.value
        ? {
            ...filterParams,
            start: filterParams.start
              ? format(
                  new Date(filterParams.start),
                  datePattern.INPUT_DATE + ' 00:00:00'
                )
              : undefined,
            end: filterParams.end
              ? format(
                  new Date(filterParams.end),
                  datePattern.INPUT_DATE + ' 23:59:59'
                )
              : undefined,
          }
        : {
            // 필터가 적용되어있지 않으면 키워드와 파트너아이디, 정렬만 사용한다
            partnerId: filterParams.partnerId,
            sortBy: filterParams.sortBy,
            page: filterParams.page,
            pageSize: filterParams.pageSize,
          };

    const fetchSettlementLists = async () => {
      console.log('finalFilterParams', getAppliedFilterParams());
      try {
        const { data } = await partnerAPI.adminOrder.adminOrderSettlementList(
          getAppliedFilterParams()
        );
        list.value = data as any;

        const { data: summary } = await partnerAPI.adminOrder.adminOrderSettlementSummary(
          getAppliedFilterParams()
        );
        summaryData.value = (summary as any).data;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    onMounted(fetchSettlementLists);

    const startRef = toRef(filterParams, 'start');
    const endRef = toRef(filterParams, 'end');
    const categoryIdRef = toRef(filterParams, 'categoryDepth1Id');
    const orderTypeRef = toRef(filterParams, 'orderTypeRef');
    const enableBidRef = toRef(filterParams, 'enableBid');
    const settlementStatusRef = toRef(filterParams, 'settlementStatus');
    const showSeoulAuctionRef = toRef(filterParams, 'showSeoulAuction')

    watch(
      [isFilterOn, startRef, endRef, categoryIdRef, settlementStatusRef, orderTypeRef, enableBidRef, showSeoulAuctionRef],
      ([isFilterOn]) => {
        // 필터 활성화 상태에서 watch하는 파라미터가 업데이트되면 검색 실행
        if (isFilterOn) {
          filterParams.page = 0;
          executeSearch();
        }
      }
    );

    watch([filterParams, isFilterOn], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchSettlementLists();
    });

    const excel = () => {
      const handleExcelDownload = _.throttle(async () => {
        try {
          const { data } = await partnerAPI.adminOrder.adminOrderSettlementList(
              {
                ...getAppliedFilterParams(),
                page: 0,
                pageSize: 100000,
              }
          )
          const list = (data as any).data
          if (list.length === 0) {
            alert('다운로드할 데이터가 없습니다.')
            return
          }
          makeExcel(list)
        } catch (e) {
          console.error(e)
          alert(getServerErrorMessage(e)|| '엑셀 생성 실패')
        }
      }, 10000)

      const makeExcel = (settlementList) => {
        const { orderStatus } = salesAndOrderStatus();
        const transformed = settlementList.map((product) => {
          // PG 수수료 임시 계산
          const pgFee = product.payMethod === 'VBANK' ? 330
              : product.payMethod === 'CARD' ? product.payAmount * 2.97 / 100
              : 0
          return {
            'LOT#': product.lot,
            '판매자(파트너)상호': product.partnerName,
            '작가명(브랜드명)': product.artistName || product.brandName,
            '상품명': product.title,
            '현재가(낙찰가)': convertNumWithComma(product.totalPrice, ''),
            '배송비': convertNumWithComma(product.totalShippingFee, ''),
            '결제금액': convertNumWithComma(product.payAmount, ''),
            '판매옵션': `${product.enableBid ? 'Bid Now' : 'Buy Now Only'}${product.enableBuyNow ? '(Buy Now)' : ''}`,
            '수수료': convertNumWithComma(product.totalFee, ''),
            '부가세': convertNumWithComma(product.vat, ''),
            '정산금액': convertNumWithComma(product.settlementAmount, ''),
            'PG수수료': convertNumWithComma(Math.round(pgFee), ''),
            '결제완료시간': product.payDate ? format(new Date(product.payDate), 'yyyy/MM/dd HH:mm') : '',
            '구매확정시간': product.completeDate ? format(new Date(product.completeDate), 'yyyy/MM/dd HH:mm') : '',
            '결제수단': product.payMethod === 'CARD' ? '신용카드' : product.payMethod === 'VBANK' ? '가상계좌' : '',
            '구매자이름': product.buyerName,
            '구매자전화번호': product.buyerMobile,
            '정산상태': product.settlementStatus === 'waiting' ? '정산대기중' : product.settlementStatus === 'complete' ? '정산완료' : product.settlementStatus === 'hold' ? '정산보륲' : '',
            '주문상태': orderStatus[product.orderStatus]?.label,
          }
        })
        .sort((p, n) => p['구매확정시간'] < n['구매확정시간'] ? 1 : -1)

        const dataWS = XLSX.utils.json_to_sheet(transformed)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS, '정산목록')
        XLSX.writeFile(wb, `정산_목록_${format(new Date(), 'yyyyMMddHHmm')}.xlsx`)
      }


      const handleWaitExcelDownload = _.throttle(async () => {
        try {
          const { data } = await partnerAPI.adminOrder.adminOrderSettlementList(
              {
                settlementStatus: 'waiting',
                page: 0,
                pageSize: 100000,
              }
          )
          const list = (data as any).data
          if (list.length === 0) {
            alert('다운로드할 데이터가 없습니다.')
            return
          }
          makeWaitExcel(list)
        } catch (e) {
          console.error(e)
          alert(getServerErrorMessage(e)|| '생성 실패')
        }
      }, 10000)

      const makeWaitExcel = (settlementList) => {
        const transformed = settlementList.map((product) => {

          return {
            'partner_id#': `partner_id${product.partnerId}`,
            'yyyyMMdd': format(new Date(product.settlementDueDate), 'yyyyMMdd'),
            '정산금액': product.settlementAmount,
          }
        })
        const dataWS = XLSX.utils.json_to_sheet(transformed,  {skipHeader:true})
        const csv = XLSX.utils.sheet_to_csv(dataWS)

        const pom = document.createElement('a')
        const blob = new Blob([csv],{type: 'text/csv;charset=utf-8;'})
        pom.href = URL.createObjectURL(blob)
        pom.setAttribute('download', `지급대행용_정산_목록_${format(new Date(), 'yyyyMMddHHmm')}.txt`)
        pom.click()
      }

      return {
        handleExcelDownload,
        handleWaitExcelDownload
      }
    }

    return {
      filterParams,
      list,
      summaryData,
      toggleApplyFilters,
      handleChangePage,
      handleChangePageSize,
      handleChangeSort,
      searchPartner,
      removeSearchPartner,
      executeSearch,
      handleClickSearch,
      isFilterOn,
      handleChangeFilterParams,
      fetchSettlementLists,
      convertNumWithComma,
      ...excel()
    };
  },
});
